.pattern-container-light,
.pattern-container-dark {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.pattern-container-light {
  background: linear-gradient(-90deg, #f2f2f2 1px, transparent 1px),
    linear-gradient(#f2f2f2 1px, transparent 1px);
  background-size: 20px 20px;
  background-position: center;
}

.pattern-container-dark {
  background: linear-gradient(-90deg, #333 1px, transparent 1px),
    linear-gradient(#333 1px, transparent 1px);
  background-size: 20px 20px;
  background-position: center;
}